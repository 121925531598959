.page-container {
    width: 100%;
    margin: 0 auto;
}

.page-content {
    padding: 5px
}

@media (min-width: 480px) {
    .page-container {
        max-width: 480px;
    }
}

@media (min-width: 576px) {
    .page-container {
        max-width: 576px;
    }
}

@media (min-width: 768px) {
    .page-container {
        max-width: 768px;
    }
    .page-content {
        padding: 20px
    }
}

@media (min-width: 992px) {
    .page-container {
        max-width: 992px;
    }
}

@media (min-width: 1200px) {
    .page-container {
        max-width: 1200px;
    }
    .page-content {
        padding: 50px
    }
}

@media (min-width: 1600px) {
    .page-container {
        max-width: 1600px;
    }
}


.logo {
    float: left;
    height: 64px;
    width: 310px;
}

@media (max-width: 800px) {
    .logo {
        width: 120px;
    }
}

.language-selector {
    float: right;
    margin-right: 30px;
}

.language-selector button {
    color: white;
    font-size: 1.2em;
}

.language-selector button .anticon {
    margin-right: 5px;
}

.auth-info {
    float: right;
    width: 300px;
}

.login-display {
    color: white;
    font-size: 1.2em;
    font-weight: 600;
}

.nav-in-drawer .login-display {
    color: rgba(0, 0, 0, 0.88);
}
.nav-in-drawer .login-display button,
.nav-in-drawer .language-selector button {
    color: #1677ff;
}

.nav-in-drawer .login-display {
    margin-left: 15px;
}

.nav-in-drawer div {
    float: none;
}

.burger-button {
    float: right;
}
